const dev = {
  AUTH_API_URL: process.env.AUTH_API_URL || 'https://localhost:44321',
  cognito: {
    AWS_CLIENT_ID: process.env.AWS_CLIENT_ID || '65mgo09b2ps7km60f3300t4bal',
    AWS_USERPOOL_ID: 'ca-central-1_G7zoQLSzQ',
    AWS_AUTH_URL: process.env.AWS_AUTH_URL
      ? process.env.AWS_AUTH_URL
      : 'https://demo.auth.ca-central-1.amazoncognito.com',
  },
  FBO_PERFORMACE_URL: process.env.FBO_PERFORMACE_URL
    ? process.env.FBO_PERFORMACE_URL
    : 'https://fbo-performance-qa.azurewebsites.net',
  GTM_KEY: process.env.GTM_KEY || 'GTM-5JVF36B',
  rollbar: {
    captureUncaught: process.env.ROLLBAR_CAPTURE_UNCAUGHT
      ? process.env.ROLLBAR_CAPTURE_UNCAUGHT === 'true'
      : false,
    captureUnhandledRejections: process.env.ROLLBAR_CAPTURE_UNHANDLED
      ? process.env.ROLLBAR_CAPTURE_UNHANDLED === 'true'
      : false,
    accessToken: process.env.ROLLBAR_ACCESS_TOKEN || '123',
    enabled: process.env.ROLLBAR_ENABLED || 'false',
    payload: {
      environment: process.env.ROLLBAR_ENVIRONMENT || 'local',
    },
  },
  GOOGLE_RECAPTCHA_KEY:
    process.env.GOOGLE_RECAPTCHA_KEY ||
    '6Le7yUMoAAAAAJNa8h71NPySvz2lyd_zNhM7JQvm',
  BLOB_STORAGE_URL:
    process.env.BLOB_STORAGE_URL ||
    'https://procomstoragedev.blob.core.windows.net',
  LINKEDIN: {
    CLIENT_ID: process.env.LINKEDIN_CLIENT_ID || '', // production key only. Ask in main channel if needed.
    AUTH_URL: 'https://www.linkedin.com/oauth/v2',
  },
  MAP_FBO_USER_SERVICE_API_ID:
    process.env.MAP_FBO_USER_SERVICE_API_ID || '104746405f46cc651e33',
  MAP_FBO_USER_SERVICE_API_KEY:
    process.env.MAP_FBO_USER_SERVICE_API_KEY ||
    'd65eccdd567b1f35c2d9d54bfe6a6d6046ef6d2d72dcc5cefa5ecf772889f04a7f8a0895a9d4ef88',
  MAP_FBO_USER_SERVICE_BASE_URL:
    process.env.MAP_FBO_USER_SERVICE_BASE_URL || 'https://localhost:5001/',
  ENABLE_CONTRACTOR_LOGIN: process.env.ENABLE_CONTRACTOR_LOGIN || 'true',
  ENABLE_CLIENT_PORTAL_SIGNUP:
    process.env.ENABLE_CLIENT_PORTAL_SIGNUP || 'true',
  ENABLE_PERFORMANCE_TRACKING:
    process.env.ENABLE_PERFORMANCE_TRACKING || 'false',
  ENABLE_DATA_TRACKING: process.env.ENABLE_DATA_TRACKING ?? 'false',
  EY_DEMO_USER: 'alessandra.willsher@procom.ca',
  EY_DEMO_LOGO: 'true',
  EY_CONTRACTOR_MANAGEMENT_URL:
    'https://app.gorillaworks.io/version-514vb/contractor_management',
}

const ci = {
  AUTH_API_URL: '#{AUTH_API_URL}#',
  cognito: {
    AWS_CLIENT_ID: '#{AWS_CLIENT_ID}#',
    AWS_USERPOOL_ID: '#{AWS_USERPOOL_ID}#',
    AWS_AUTH_URL: '#{AWS_AUTH_URL}#',
  },
  FBO_PERFORMACE_URL: '#{FBO_PERFORMACE_URL}#',
  GTM_KEY: '#{GTM_KEY}#',
  rollbar: {
    accessToken: '#{Rollbar.FrontEndAccessToken}#',
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: '#{Rollbar.FrontEndEnabled}#',
    payload: {
      environment: '#{Rollbar.Environment}#',
    },
  },
  GOOGLE_RECAPTCHA_KEY: '#{GOOGLE_RECAPTCHA_KEY}#',
  BLOB_STORAGE_URL: '#{BLOB_STORAGE_URL}#',
  LINKEDIN: {
    CLIENT_ID: '#{LINKEDIN_CLIENT_ID}#',
    AUTH_URL: '#{LINKEDIN_AUTH_URL}#',
  },
  MAP_FBO_USER_SERVICE_API_ID: '#{MAP_FBO_USER_SERVICE_API_ID}#',
  MAP_FBO_USER_SERVICE_API_KEY: '#{MAP_FBO_USER_SERVICE_API_KEY}#',
  MAP_FBO_USER_SERVICE_BASE_URL: '#{MAP_FBO_USER_SERVICE_BASE_URL}#',
  ENABLE_CONTRACTOR_LOGIN: '#{ENABLE_CONTRACTOR_LOGIN}#',
  ENABLE_CLIENT_PORTAL_SIGNUP: '#{ENABLE_CLIENT_PORTAL_SIGNUP}#',
  ENABLE_PERFORMANCE_TRACKING: '#{ENABLE_PERFORMANCE_TRACKING}#',
  ENABLE_DATA_TRACKING: '#{ENABLE_DATA_TRACKING}#',
  EY_DEMO_USER: '#{EY_DEMO_USER}#',
  EY_DEMO_LOGO: '#{EY_DEMO_LOGO}#',
  EY_CONTRACTOR_MANAGEMENT_URL: '#{EY_CONTRACTOR_MANAGEMENT_URL}#',
}
export const environment = {
  ...(process.env.REACT_APP_STAGE === 'ci' ? ci : dev),
}
